import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import Carousel from "nuka-carousel"

const BarwonPage = ({ data }) => (
  <Layout>
    <SEO title="Barwon Heads Dental Project" />
    <h1>Barwon Heads Dental Project</h1>

    <p>Formerly a run down newsagency has now been restored & given a new life as the first and only dental clinic in Barwon Heads. Collaborating with our great friend Dr Jack Milgate to bring his dream to life of opening his own state of the art dental clinic was a great success, and has become a true asset to the surf coast community.</p>

   <div className="flex justify-center">
      <div className="w-2/3 h-auto py-6">
        <Carousel renderBottomCenterControls={false}>
          {data.barwonPhotos.edges.map(edge => {
            return <Img fluid={edge.node.childImageSharp.fluid} />
          })}
        </Carousel>
      </div>
    </div>
  </Layout>
)

export default BarwonPage

export const query = graphql`
  query {
    barwonPhotos: allFile(
      filter: { 
        absolutePath: { regex: "/projects/commercial/barwon-heads-dental/" }
        extension: { regex: "/(jpg)|(jpeg)/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
